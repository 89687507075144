export class Gebruiker {
  id: string;
  email: string;
  voornaam: string;
  achternaam: string;
  admin: boolean;
  auth: string;
  hoofdTrainer: boolean;
}


export enum TrainingStatus {
  OPEN = "OPEN", AANWEZIG = "AANWEZIG", AFWEZIG = "AFWEZIG", AFGELAST = "AFGELAST"
}

export class Training {
  /*{"id":"4028802b753123e801753123eb46000e",
    "groepName":"Cadetten B",
    "beginUur":"2020-09-05T18:00:00",
    "eindUur":"2020-09-05T19:30:00",
    "zaalNaam":"St-Ben",
    "bevestiged":null}*/

  id: string;
  groepName: string;
  beginUur: Date;
  eindUur: Date;
  zaalNaam: string;
  bevestiged: boolean;
  status: TrainingStatus;
  locked: boolean;
  medetrainers: MedeTrainer[]

  constructor() {
    this.beginUur = new Date();
    this.eindUur = new Date();
  }
}

export class MedeTrainer {
  id: string;
  naam: string;
  aanwezig: boolean;
  hoofdtrainer: boolean;
}

export class TrainingSearchOptions {
  groepen: string[];
  zaalen: string[];
  trainers: string[];
}


export class trainingsSearchResult {
  id: string;
  groep: string;
  beginUur: Date;
  eindUur: Date;
  zaal: string;
  hoofdTrainer:string;
  registraties: TrainingsRegistratieSearchResult[];
}

export class TrainingsRegistratieSearchResult {
  id: string;
  trainerNaam: string;
  status: string;
  bevestigdOp?: Date;
  locked: boolean;


  constructor(id: string, trainerNaam: string, status: string, bevestigdOp: Date, locked: boolean) {
    this.id = id;
    this.trainerNaam = trainerNaam;
    this.status = status;
    this.bevestigdOp = bevestigdOp;
    this.locked = locked;
  }
}

export class Trainer {
  id: string;
  trainerNaam: string;
  trainerNaamKort: string;
}

