<div class="center v-stretch " *ngIf="!listView">
  <mat-card class="center full-width">

    <mat-card-content *ngIf="trainingList.length != 0">
      <mat-card-actions *ngIf="trainingList.length != 0" class="navi-actions">
        <button mat-raised-button class="button-left" (click)="vorige()" [disabled]="!hasVorige()">< Vorige</button>
        <button mat-flat-button *ngIf="tellers.open != 0" class="red" (click)="goToLastToConfirm()">Nog {{tellers.open}}
          te bevestigen
        </button>
        <button mat-flat-button *ngIf="tellers.open == 0" class="green">Alles bevestigd</button>
        <button mat-raised-button class="button-right" (click)="volgend()" [disabled]="!hasVolgende()">Volgende >
        </button>
      </mat-card-actions>
      <p class="reg-container">
        <span class="reg-groep">  {{actieveTraining.groepName}} </span><br/>
        <span class="reg-datum"> op {{actieveTraining.beginUur | parseDag }} </span><br/>
        <span class="reg-datum"> {{actieveTraining.beginUur | date: 'd/MM/yyyy' }} </span><br/>
        <span class="reg-uren"> van {{actieveTraining.beginUur | date: 'HH:mm'}}
          tot {{actieveTraining.eindUur | date: 'HH:mm'}}  </span><br/>
        <!--        <span class="reg-zaal"> in {{actieveTraining.zaalNaam}}  </span><br/>-->
        <user *ngFor="let trainer of actieveTraining.medetrainers" class="reg-samen-met"
              [medeTrainer]="trainer">
          <!--          met {{actieveTraining.medetrainers.join(", ")}}  -->
        </user>
        <br/>
        <br/>
        <br/>

        <br/>
      </p>

    </mat-card-content>
    <mat-card-content *ngIf="trainingList.length == 0">
      Geen trainingen te bevestigen.
      <button mat-raised-button color="primary" *ngIf="globals.adminPanel" mat-button [routerLink]="['/administratie/dashboard']">
        <mat-icon>settings_applications</mat-icon> Open administratie pagina
      </button>
    </mat-card-content>
    <!--    OPEN -->
    <mat-card-actions class="card-actions"
                      *ngIf=" trainingList.length != 0 &&  actieveTraining.status == 'OPEN' && !toekomstig() && !actieveTraining.locked">
      <button mat-raised-button [disabled]="actieveTraining.bevestiged != null" (click)="bevestig(true)">Aanwezig
      </button>
      <button mat-raised-button [disabled]="actieveTraining.bevestiged != null" (click)="bevestig(false)">Afwezig
      </button>
    </mat-card-actions>

    <mat-card-actions class="card-actions-future"
                      *ngIf=" trainingList.length != 0 && actieveTraining.status == 'OPEN' && toekomstig() && !actieveTraining.locked">
      <button *ngIf="toekomstig()" mat-raised-button disabled>In de toekomst</button>
      <button mat-raised-button [disabled]="actieveTraining.bevestiged != null" (click)="bevestig(false)">Afmelden
      </button>
      <button *ngIf="actieveTraining.locked" mat-icon-button disabled
              class="right">
        <mat-icon>lock</mat-icon>
      </button>
    </mat-card-actions>
<!--    confirmed-->
    <mat-card-actions class="card-actions-confirmed"
                      *ngIf=" trainingList.length != 0 && actieveTraining.status != 'OPEN' && !actieveTraining.locked">
      <button *ngIf="toekomstig()" mat-raised-button disabled>In de toekomst</button>
      <button mat-raised-button disabled>{{actieveTraining.status}}</button>
      <button *ngIf="!actieveTraining.locked" mat-icon-button aria-label="aanpassen" (click)="reopen()"
              class="right">
        <mat-icon>edit</mat-icon>
      </button>
    </mat-card-actions>
<!--    locked-->
    <mat-card-actions class="card-actions-confirmed"
                      *ngIf=" trainingList.length != 0 && actieveTraining.locked">
      <button mat-raised-button disabled>{{actieveTraining.status}}</button>
      <button *ngIf="actieveTraining.locked" mat-icon-button disabled
              class="right">
        <mat-icon>lock</mat-icon>
      </button>
    </mat-card-actions>
  </mat-card>
</div>
<div *ngIf="listView">
  <mat-card class="center full-width">
    <mat-card-content class="overview"  >
      <mat-card-title>Overzicht trainingen</mat-card-title>
      <mat-card-header>gelimiteerd per halfjaar <br/>
        onbevestigd: {{tellers.open}} / totaal: {{tellers.totaal}} </mat-card-header>
      <mat-divider></mat-divider>
      <mat-list-item *ngFor="let training of trainingList">
        <div class="list-item">
          <mat-label>
            {{training.beginUur | parseDag}} {{training.beginUur | date: 'd/MM/yyyy HH:mm'}} {{training.groepName}} <br>
            met <label *ngFor="let medetrainer of training.medetrainers">{{medetrainer.naam}} </label>
          </mat-label>
          <mat-icon *ngIf="training.status == 'OPEN'">query_builder</mat-icon>
          <mat-icon *ngIf="training.status == 'AANWEZIG'">check</mat-icon>
          <mat-icon *ngIf="training.status == 'AFWEZIG'">cancel</mat-icon>
        </div>
        <mat-divider></mat-divider>
      </mat-list-item>

    </mat-card-content>
  </mat-card>
</div>
