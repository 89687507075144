<mat-accordion>

  <mat-expansion-panel *ngFor="let tr of training" hideToggle [expanded]="focusedTraining === tr.id" >
    <mat-expansion-panel-header>
      <mat-panel-title>
        {{tr.groep}}
      </mat-panel-title>
      <mat-panel-description>
        <!--              {{tr.zaal}} <br>-->
        {{tr.beginUur | parseDag }} {{tr.beginUur | date: 'd/MM/yyyy'}} {{tr.beginUur | date: 'HH:mm'}}
        - {{tr.eindUur | date: 'HH:mm'}}
      </mat-panel-description>
    </mat-expansion-panel-header>
    <p>Beheerd door: <button mat-flat-button>{{tr.hoofdTrainer}}</button>
    <p>Trainers:
      <button mat-flat-button *ngFor="let reg of tr.registraties">
        {{reg.trainerNaam}}
        <mat-icon *ngIf="reg.status == 'OPEN'">query_builder</mat-icon>
        <mat-icon *ngIf="reg.status == 'AANWEZIG'">check</mat-icon>
        <mat-icon *ngIf="reg.status == 'AFWEZIG'">cancel</mat-icon>
        <mat-icon *ngIf="reg.locked == true">lock</mat-icon>
      </button>
      <button mat-flat-button (click)="trainerToevoegen(tr)">
        <mat-icon>person_add</mat-icon>
      </button>
      <button mat-flat-button (click)="editTraining(tr)">
        <mat-icon>settings</mat-icon>
      </button>
    </p>
  </mat-expansion-panel>
</mat-accordion>
