import {Component, OnInit} from '@angular/core';
import {ActivatedRoute} from "@angular/router";
import {Gebruiker} from "../common/model";

@Component({
  selector: 'app-error',
  templateUrl: './error.component.html',
  styleUrls: ['./error.component.scss']
})
export class ErrorComponent implements OnInit {
  error: String;
  admin: boolean;

  gebruiker:Gebruiker;

  constructor(private route: ActivatedRoute) {

    this.gebruiker = JSON.parse(localStorage.getItem('currentUser'));
    this.error = this.route.snapshot.queryParamMap.get('error');

    if(this.gebruiker == null){
      this.gebruiker = new Gebruiker();
    }
  }

  ngOnInit(): void {
  }

}
