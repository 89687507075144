import {Component, Inject, OnInit} from '@angular/core';
import {FormControl, Validators} from "@angular/forms";
import {Observable} from "rxjs";
import {map} from "rxjs/operators";
import {GebruikerDetail, GebruikerStatistics} from "../../common/adminModel";
import {BackendService} from "../../common/BackendService";
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import {MatSnackBar} from "@angular/material/snack-bar";

@Component({
  selector: 'app-admin-gebruiker',
  templateUrl: './admin-gebruiker.component.html',
  styleUrls: ['./admin-gebruiker.component.scss']
})
export class AdminGebruikerComponent implements OnInit {

  myControl = new FormControl();
  public alleGebruikers: GebruikerDetail[];
  filteredOptions: Observable<GebruikerDetail[]>;
  noOptions: boolean;

  constructor(private backend: BackendService,
              public dialog: MatDialog,
              private _snackBar: MatSnackBar,
  ) {
    this.myControl.reset()

  }

  ngOnInit() {
    this.backend.alleGebruikers()
      .subscribe(r => this.alleGebruikers = r)

    this.filteredOptions = this.myControl.valueChanges
      .pipe(
        map(name => name ? this._filter(name) : this.alleGebruikers.slice(0,20))
      );

    this.filteredOptions.subscribe(val => {
      this.noOptions = val.length === 0
    });

  }

  openEditUserDialog(id: string): void {
    var userToChange = this.alleGebruikers.find(g => g.id == id);
    var gebruikerBackup = Object.assign({}, userToChange);
    const dialogRef = this.dialog.open(EditUserDialog, {
      width: '500px',
      data: {
        id: id,
        gebruiker: userToChange
      },
      hasBackdrop: true
    });

    dialogRef.afterClosed().subscribe(user => {
      if (user != undefined && user != "") {
        this.backend.updateGebruiker(user).subscribe(r => {

            this._snackBar.open(user.voornaam + " " + user.achternaam + " successvol opgeslagen", null, {
              duration: 3000
            })
          },
          error => {
            this._snackBar.open("Opslaan gefaald" + error, null, {
              duration: 3000
            })
          }
        );
      } else {
        this.alleGebruikers.splice(this.alleGebruikers.indexOf(userToChange), 1);
        //oldUser
        this.alleGebruikers.push(gebruikerBackup);
        this.myControl.setValue(this.myControl.value)
      }
    });
  }

  displayFn(geb: string): string {
    //  console.log(geb);
    //  var gebruiker: Gebruiker =  this.alleGebruikers.find(g => g.id == geb)
    //  return geb != null ? gebruiker.voornaam + ' ' + gebruiker.achternaam : '?';
    return geb;
  }

  private _filter(name: string): GebruikerDetail[] {
    if (!name) return this.alleGebruikers;
    const filterValue = name.toLowerCase();

    return this.alleGebruikers.filter(g => g.voornaam.toLowerCase().indexOf(filterValue) === 0
      || g.achternaam.toLowerCase().indexOf(filterValue) === 0
      || g.email.toLowerCase().indexOf(filterValue) === 0
      // || g.nickname != null ? g.nickname.toLowerCase().indexOf(filterValue) === 0 : false
      || g.id.toLowerCase() === filterValue);
  }
}

@Component({
  selector: 'edit-user-dialog',
  templateUrl: 'edit-user-dialog.html',
})
export class EditUserDialog {

  public gebruiker: GebruikerDetail;
  public gebruikerStats: GebruikerStatistics = new GebruikerStatistics();

  emailFormControl = new FormControl('', [
    Validators.required,
    Validators.email,
  ]);

  constructor(
    public dialogRef: MatDialogRef<EditUserDialog>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private backend: BackendService
  ) {

    this.gebruiker = data.gebruiker;
    this.backend.gebruikerStatistics(data.gebruiker.id)
      .subscribe(val => this.gebruikerStats = val )
  }

  resetPassword() {
    this.backend.resetPassword(this.data.gebruiker.id).subscribe(
      r => this.gebruiker = r
    )
  }

  onNoClick(): void {
    this.dialogRef.close();
  }
}
