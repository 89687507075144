import {Component, Input, OnInit} from '@angular/core';
import {MedeTrainer} from "../common/model";

@Component({
  selector: 'user',
  styleUrls: ['./user.component.scss'],
  template:`
    <span *ngIf="medeTrainer.aanwezig">{{medeTrainer.naam}}</span>
    <span *ngIf="!medeTrainer.aanwezig" class="afwezig">{{medeTrainer.naam}}</span>
  `
})
export class UserComponent implements OnInit {

  @Input() medeTrainer:MedeTrainer;

  constructor() { }

  ngOnInit(): void {
  }

}
