import {Injectable} from "@angular/core";
import {HttpErrorResponse, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse} from "@angular/common/http";
import {Globals} from "./globalsService";
import {Router} from "@angular/router";
import {tap} from "rxjs/operators";

@Injectable()
export class AliveInterceptor implements HttpInterceptor {

  private outgoing: number = 0
  private incomming: number = 0

  constructor(private globals: Globals, private router: Router) {
  }

  intercept(req: HttpRequest<any>, next: HttpHandler) {

    this.globals.loading.next(true);
    this.outgoing++;

    return next.handle(req).pipe(tap((resp: HttpResponse<any>) => {
        //got a response back!
        this.incomming += 2;

        if (this.incomming == this.outgoing * 4) {
          this.globals.loading.next(false);
        }

        this.globals.offline = false;
      },
      (err: any) => {
        // this.globals.loading.next(false);
        if (err instanceof HttpErrorResponse) {

          if (err.status == 0) {
            this.globals.offline = true
            console.log("Offline modus");
            this.globals.loading.next(false);
            this.outgoing = 0;
            this.incomming = 0;
          }

          if (err.status == 401) {
            //this.logout()
          } else {
            //       this.router.navigate(["error"], {queryParams: {error: JSON.stringify(err)}})
          }
        }
      }));
  }
}
