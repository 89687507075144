import {Pipe, PipeTransform} from "@angular/core";

@Pipe({name: 'parseDag'})
export class parseDag implements PipeTransform {
    transform(date: Date): string {
      var days = ['Zondag', 'Maandag', 'Dinsdag', 'Woensdag', 'Donderdag', 'Vrijdag', 'Zaterdag'];

      const date2 = new Date(date.toString());
      return date2.toLocaleDateString("nl-NL", { weekday: 'long' });
    }
}
