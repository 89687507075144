import {Injectable} from "@angular/core";
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {Router} from "@angular/router";
import {Gebruiker} from "./model";
import {CookieService} from "ngx-cookie-service";
import {Globals} from "./globalsService";
import {environment} from "../../environments/environment";


@Injectable()
export class LoginService {

  user: string
  password: string
  backendUrl: string = environment.backendUrl + "verify-login";
  backenLogoutdUrl: string = environment.backendUrl + "logout";


  constructor(private http: HttpClient, private router: Router, private cookieService: CookieService, private globals: Globals) {


  }

  public checkLogin(user, password): Promise<boolean> {


    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'Basic ' + btoa(user + ':' + password)
      })
    };

    this.user = user;
    this.password = password;

    //console.log('trying to log in with:' + user + ":" + password)

    return new Promise<boolean>((resolve) => {
      this.http.get<Gebruiker>(this.backendUrl, httpOptions) //
        .subscribe(resp => {
            resp.auth = btoa(user + ':' + password);
            localStorage.setItem('currentUser', JSON.stringify(resp));
            this.globals.showMenu = true;
            this.globals.menuSearch = resp.admin || resp.hoofdTrainer;
            this.globals.adminPanel = resp.admin;
            console.log('Welcome ' + user);
            resolve(true)
          },
          err => {
            if (err.status == 0) {
              this.globals.offline = true;
              resolve(false);
              return
            }

            if (err.status == 401) {
              //this.logout()
            } else {
              this.router.navigate(["error"], {queryParams: {error: JSON.stringify(err)}})
            }
            resolve(false)
          });

    });
  }

  logout() {

    console.log("until next time " + this.user)
    //this.http.get(this.backenLogoutdUrl, headers).subscribe()
    localStorage.removeItem('currentUser');
    this.globals.showMenu = false;
    this.router.navigate(["login"], {queryParams: {email: this.user}})
  }
}
