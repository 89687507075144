import {ChangeDetectorRef, Component} from '@angular/core';
import {Globals} from "./common/globalsService";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'Training logger';
  loading: boolean;

  ngOnInit() {

  }

  constructor(public globals: Globals, private changeDetector: ChangeDetectorRef) {

  }

  ngAfterViewChecked() {
    //for loader to work
    this.changeDetector.detectChanges();
  }


}
