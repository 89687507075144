export class Statistics {
  totalTrainers: number;
  totalGroeps: number;
  totalTraining: number;
  registraties: Registraties;
}

export class Registraties {
  total: number;
  locked: number;
  openTotNu: number;
  openInToekomst: number;
  open: number;
  aanwezig: number;
  afwezig: number;
}

export class GebruikerDetail {
  id: string
  email: string
  voornaam: string
  achternaam: string
  geboortedatum: Date
  nickname: string
  diploma: boolean
  hoofdTrainerVanGroep: string[]
  beheerder: boolean
  trainer: boolean
  nonActief: boolean
  resetPasswordLink: string
}
export class GebruikerStatistics {
  totaalTrainingen: number;
  totaalTrainingenTotVandaag: number;
  totaalOpenTrainingenTotVandaag: number;
  laatsteRegistratie: Date;
}
