<div class=" center v-stretch" *ngIf="resultaten.length == 0">
  <mat-card class="center full-width ">
    <mat-card-title>Beheer trainingen</mat-card-title>
    <mat-card-content>
      <form [formGroup]="searchForm" #f="ngForm">
        <mat-form-field appearance="standard" class="full-width">
          <mat-label>Groep</mat-label>
          <mat-select formControlName="groep" [(ngModel)]="selectedGroep">
            <mat-option *ngIf="searchOptions.groepen.length > 1">Alle</mat-option>
            <mat-option *ngFor="let groep of searchOptions.groepen" [value]="groep">
              {{groep}}
            </mat-option>
          </mat-select>
        </mat-form-field>

        <!--        <mat-form-field appearance="fill">-->
        <!--          <mat-label>Enter a date range</mat-label>-->
        <!--          <mat-date-range-input [rangePicker]="picker">-->
        <!--            <input matStartDate placeholder="Start date">-->
        <!--            <input matEndDate placeholder="End date">-->
        <!--          </mat-date-range-input>-->
        <!--          <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>-->
        <!--          <mat-date-range-picker #picker></mat-date-range-picker>-->
        <!--        </mat-form-field>-->

        <mat-form-field appearance="standard" class="full-width">
          <mat-label>van (optioneel)</mat-label>
          <input formControlName="fromDate" matInput [matDatepicker]="picker_from">
          <mat-datepicker-toggle matSuffix [for]="picker_from"></mat-datepicker-toggle>
          <mat-datepicker touchUi #picker_from></mat-datepicker>
        </mat-form-field>

        <mat-form-field appearance="standard" class="full-width">
          <mat-label>tot (optioneel)</mat-label>
          <input formControlName="toDate" matInput [matDatepicker]="picker_to">
          <mat-datepicker-toggle matSuffix [for]="picker_to"></mat-datepicker-toggle>
          <mat-datepicker touchUi #picker_to></mat-datepicker>
        </mat-form-field>

<!--        <mat-form-field appearance="standard" class="full-width">-->
<!--          <mat-label>Zaal</mat-label>-->
<!--          <mat-select formControlName="zaal" [(ngModel)]="selectedZaal">-->
<!--            <mat-option *ngIf="searchOptions.zaalen.length > 1">Alle</mat-option>-->
<!--            <mat-option *ngFor="let zaal of searchOptions.zaalen" [value]="zaal">-->
<!--              {{zaal}}-->
<!--            </mat-option>-->
<!--          </mat-select>-->
<!--        </mat-form-field>-->

        <mat-form-field appearance="standard" class="full-width">
          <mat-label>Trainer (optioneel)</mat-label>
          <mat-select formControlName="trainer" [(ngModel)]="selectedTrainer">
            <mat-option *ngIf="searchOptions.trainers.length > 1">Alle</mat-option>
            <mat-option *ngFor="let trainer of searchOptions.trainers" [value]="trainer">
              {{trainer}}
            </mat-option>
          </mat-select>
        </mat-form-field>

      </form>
    </mat-card-content>
    <mat-card-actions class="card-actions">
      <!--      [disabled]="!searchForm.valid"-->
      <button mat-raised-button [disabled]="searchOptions.groepen.length == 0" (click)="search()">Zoeken
      </button>
    </mat-card-actions>
  </mat-card>
</div>

<app-list-training *ngIf="resultaten.length != 0"
                   [training]="resultaten"
                   [searchParams]="queryParams"
></app-list-training>
