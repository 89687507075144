import {Component, OnInit} from '@angular/core';
import {FormControl, FormGroupDirective, NgForm, Validators} from "@angular/forms";
import {LoginService} from "../common/LoginService";
import {ErrorStateMatcher} from "@angular/material/core";
import {ActivatedRoute, Router} from "@angular/router";
import {MatSnackBar} from "@angular/material/snack-bar";
import {Globals} from "../common/globalsService";

/** Error when invalid control is dirty, touched, or submitted. */
export class MyErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    const isSubmitted = form && form.submitted;
    return !!(control && control.invalid && (control.dirty || control.touched || isSubmitted));
  }
}

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  emailFormControl = new FormControl('', [
    Validators.required,
    Validators.email,
  ]);

  passFormControl = new FormControl('', [
    Validators.required,
  ]);

//go
  password: string;
  email: string;
  errorFeedback: string;

  constructor(private loginService: LoginService, private router: Router,
              private route: ActivatedRoute, private _snackBar: MatSnackBar, private Globals: Globals) {
    this.email = this.route.snapshot.queryParamMap.get('email');

  }

  ngOnInit(): void {
    this.continueLogin();
    if (this.email != null) {
      //  this.errorFeedback = "Ongeldige gebruiker / wachtwoord"
    }
    this.route
      .data
      .subscribe(v => {
        if (v.logout == true) {
          this.errorFeedback = null;
          this.loginService.logout()
        }
      });


  }


  onSubmit(f: NgForm) {
    this.emailFormControl.markAsTouched();
    this.passFormControl.markAsTouched();
    if (this.matcher.isErrorState(this.passFormControl, f) || this.matcher.isErrorState(this.emailFormControl, f)) {
      return;
    }
    this.loginService.checkLogin(this.email, this.password)
      .then((success) => {
        if (success) {
          this.continueLogin()
        } else {
          if (this.Globals.offline) {
            this.errorFeedback = "Door technische problemen kan je nu niet aanmelden, probeer later opnieuw."
          } else {
            this.errorFeedback = "Ongeldige gebruiker / wachtwoord"
          }
          this._snackBar.open(this.errorFeedback, null, {
            duration: 5000
          })
        }
      })


  }


  matcher = new MyErrorStateMatcher();

  keyDownFunction(event: KeyboardEvent, f: NgForm) {
    if (event.keyCode === 13) {
      this.onSubmit(f);
    }
  }

  continueLogin() {
    const user = JSON.parse(localStorage.getItem('currentUser'));
    if (user != null) {
      //this.loginService.checkLogin(null, null);
      this.router.navigate(["log"])
    } else {

    }
  }
}
