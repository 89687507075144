import {Component, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {environment} from "../../environments/environment";


@Component({
  selector: 'app-administration',
  templateUrl: './administration.component.html',
  styleUrls: ['./administration.component.scss']
})
export class AdministrationComponent implements OnInit {

  public view: string;
  public manualLink: string = environment.manual;


  constructor(private route: ActivatedRoute) {
    this.view = this.route.snapshot.paramMap.get('view').toUpperCase();
    console.log(this.view)
  }

  ngOnInit() {
    this.route.params.subscribe(params => { //.queryParams.subscribe(params => {
      this.view = params['view'].toUpperCase();
    });
  }


}
