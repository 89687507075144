<mat-drawer-container class="example-container">
  <mat-drawer mode="side" opened>
    <mat-list>
      <mat-list-item><H2>Administratie</H2></mat-list-item>
      <mat-list-item [routerLink]="['/administratie/dashboard']">Dashboard</mat-list-item>
      <mat-divider></mat-divider>
      <mat-list-item [routerLink]="['/administratie/groepen']">Groepen</mat-list-item>
      <mat-divider></mat-divider>
      <mat-list-item [routerLink]="['/administratie/gebruikers']">Gebruikers</mat-list-item>
      <mat-divider></mat-divider>
      <mat-list-item [routerLink]="['/administratie/trainingen']">Trainingen</mat-list-item>
      <mat-divider></mat-divider>
      <br>
      <mat-list-item><H2>Andere</H2></mat-list-item>
      <mat-list-item [routerLink]="['/log']">Trainer Home</mat-list-item>
      <mat-divider></mat-divider>
      <a href='{{manualLink}}'><mat-list-item> Handleiding</mat-list-item></a>
      <mat-divider></mat-divider>
      <mat-list-item [routerLink]="['/logout']">Uitloggen</mat-list-item>
    </mat-list>
  </mat-drawer>
  <mat-drawer-content>
    <app-admin-gebruiker *ngIf="(view == 'GEBRUIKERS')"></app-admin-gebruiker>
    <app-admin-dashboard *ngIf="(view == 'DASHBOARD')"></app-admin-dashboard>
    <app-admin-training *ngIf="(view == 'TRAININGEN')"></app-admin-training>
  </mat-drawer-content>
</mat-drawer-container>
