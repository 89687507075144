import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {LoginComponent} from "./login/login.component";
import {RegisterComponent} from "./register/register.component";
import {TrainingLoggerComponent} from "./training-logger/training-logger.component";
import {AuthGuard} from "./common/AuthGuard";
import {ProfielComponent} from "./profiel/profiel.component";
import {ErrorComponent} from "./error/error.component";
import {ManageTrainingComponent} from "./searchAndModTraining/manage-training/manage-training.component";
import {AdministrationComponent} from "./administration/administration.component";
import {AuthGuardAdmin} from "./common/AuthGuardAdmin";


const routes: Routes = [
  { path: 'error', component: ErrorComponent },
  { path: 'login/:email', component: LoginComponent },
  { path: 'login', component: LoginComponent },
  { path: 'logout', component: LoginComponent, data : {logout: true} },
  { path: 'registreer', component: RegisterComponent },
  { path: 'profiel', component: ProfielComponent, canActivate: [AuthGuard] },
  { path: 'log', component: TrainingLoggerComponent, canActivate: [AuthGuard] },
  { path: 'log/overzicht', component: TrainingLoggerComponent, canActivate: [AuthGuard] },
  { path: 'beheer**', redirectTo: 'beheer/trainingen', pathMatch: 'full' },
  { path: 'beheer/trainingen', component: ManageTrainingComponent, canActivate: [AuthGuard] },
  { path: 'administratie**', redirectTo: 'administratie/dashboard', pathMatch: 'full' },
  { path: 'administratie/:view', component: AdministrationComponent, canActivate: [AuthGuardAdmin] },
  { path: '**', redirectTo: 'log', pathMatch: 'full' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})

export class AppRoutingModule { }
