import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';

import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {LoginComponent} from './login/login.component';
import {RegisterComponent} from './register/register.component';
import {TrainingLoggerComponent} from './training-logger/training-logger.component';
import {AuthGuard} from "./common/AuthGuard";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {LoginService} from "./common/LoginService";
import {HTTP_INTERCEPTORS, HttpClientModule} from "@angular/common/http";
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {MatInputModule} from "@angular/material/input";
import {MatCardModule} from "@angular/material/card";
import {OverlayModule} from "@angular/cdk/overlay";
import {ProfielComponent} from './profiel/profiel.component';
import {MatCheckboxModule} from "@angular/material/checkbox";
import {ErrorComponent} from './error/error.component';
import {AuthInterceptor} from "./common/auth-interceptor.service";
import {BackendService} from "./common/BackendService";
import {MatButtonModule} from "@angular/material/button";
import {parseDag} from "./training-logger/parse-dag.pipe";
import {MatSnackBar} from "@angular/material/snack-bar";
import {MatIconModule} from "@angular/material/icon";
import {MatListModule} from "@angular/material/list";
import {MatTabsModule} from "@angular/material/tabs";
import {ManageTrainingComponent} from './searchAndModTraining/manage-training/manage-training.component';
import {MatDatepickerModule} from "@angular/material/datepicker";
import {MatSelectModule} from "@angular/material/select";
import {MatFormFieldModule} from "@angular/material/form-field";
import {MAT_DATE_LOCALE, MatNativeDateModule} from "@angular/material/core";
import {DialogPopup, ListTrainingComponent} from './searchAndModTraining/list-training/list-training.component';
import {MatExpansionModule} from "@angular/material/expansion";
import {MAT_DIALOG_DEFAULT_OPTIONS, MatDialogModule} from "@angular/material/dialog";
import {MatSidenavModule} from "@angular/material/sidenav";
import {Globals} from "./common/globalsService";
import {MatProgressBarModule} from "@angular/material/progress-bar";
import {AliveInterceptor} from "./common/alive-interceptor.service";
import {UserComponent} from './components/user.component';
import {AdministrationComponent} from './administration/administration.component';
import {AuthGuardAdmin} from "./common/AuthGuardAdmin";
import {MatAutocompleteModule} from "@angular/material/autocomplete";
import {AdminGebruikerComponent, EditUserDialog} from './administration/admin-gebruiker/admin-gebruiker.component';
import {AddTrainerComponent} from './components/add-trainer/add-trainer.component';
import {AdminDashboardComponent} from './administration/admin-dashboard/admin-dashboard.component';
import {CdkTableModule} from '@angular/cdk/table';
import {
  AdminDialogEditTraining,
  AdminTrainingComponent
} from './administration/admin-training/admin-training.component';
import {
  AdminDialogEditUser,
  AdminDialogPopup,
  AdminListTrainingComponent
} from "./administration/admin-training/admin-list-training/admin-list-training.component";


@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    RegisterComponent,
    TrainingLoggerComponent,
    ProfielComponent,
    ErrorComponent,
    parseDag,
    ManageTrainingComponent,
    ListTrainingComponent,
    DialogPopup,
    UserComponent,
    AddTrainerComponent,
    AdministrationComponent,
    AdminGebruikerComponent,
    AdminDashboardComponent,
    EditUserDialog,
    AdminTrainingComponent,
    AdminListTrainingComponent,
    AdminDialogPopup,
    AdminDialogEditUser,
    AdminDialogEditTraining,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    HttpClientModule,
    BrowserAnimationsModule,
    MatInputModule,
    ReactiveFormsModule,
    MatCardModule,
    OverlayModule,
    MatCheckboxModule,
    MatButtonModule,
    MatIconModule,
    MatListModule,
    MatTabsModule,
    MatDatepickerModule,
    MatFormFieldModule,
    MatNativeDateModule,
    MatDatepickerModule,
    MatSelectModule,
    MatExpansionModule,
    MatDialogModule,
    MatSidenavModule,
    MatProgressBarModule,
    MatAutocompleteModule,
    CdkTableModule,
  ],
  providers: [AuthGuard, AuthGuardAdmin, LoginService, BackendService, MatSnackBar, Globals,
    {provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true},
    {provide: HTTP_INTERCEPTORS, useClass: AliveInterceptor, multi: true},
    {provide: MAT_DIALOG_DEFAULT_OPTIONS, useValue: {hasBackdrop: true}},
    {provide: MAT_DATE_LOCALE, useValue: 'nl-BE'}],
  bootstrap: [AppComponent]
})
export class AppModule {
}
