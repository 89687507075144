import {
  Gebruiker,
  Trainer,
  Training,
  TrainingSearchOptions,
  TrainingsRegistratieSearchResult,
  trainingsSearchResult
} from "./model";
import {HttpClient, HttpErrorResponse, HttpParams} from "@angular/common/http";
import {Router} from "@angular/router";
import {Observable, throwError} from "rxjs";
import {catchError} from 'rxjs/operators';
import {Injectable} from "@angular/core";
import {LoginService} from "./LoginService";
import {Globals} from "./globalsService";
import {environment} from "../../environments/environment";
import {GebruikerDetail, GebruikerStatistics, Statistics} from "./adminModel";

@Injectable()
export class BackendService {

  backendUrl: string = environment.backendUrl;
  backendAdminUrl: string = environment.backendUrl + "admin/";

  constructor(private router: Router, private http: HttpClient, private loginSerivce: LoginService, private globals: Globals) {

  }

  public loadTrainingList(): Observable<Training[]> {
    // @ts-ignore
    return this.http.get<Training[]>(this.backendUrl + "mijn-trainingen")
      .pipe(catchError(e => this.handleError(e)));
  }


  bevestigTraining(id: string, aanwezig: boolean) {
    const body = {
      "id": id,
      "aanwezig": aanwezig
    }
    return this.http.post<Training>(this.backendUrl + "mijn-trainingen", body)
      .pipe(catchError(e => this.handleError(e)));
  }

  registreer(code: string, email: string, password: string) {
    const body = {
      "code": code,
      "email": email,
      "password": password
    }
    return this.http.post<Gebruiker>(this.backendUrl + "register", body)
      .pipe(catchError(e => this.handleError(e)));
  }

  getTrainingSearchOptions() {
    return this.http.get<TrainingSearchOptions>(this.backendUrl + "training-search-options")
      .pipe(catchError(e => this.handleError(e)));
  }

  zoekTrainingen(groep: string, van: Date, tot: Date, zaal: string, trainer: string) {
    let params = new HttpParams();
    if (groep != null) params = params.append("groep", groep);
    if (tot != null) params = params.append("tot", new Date(tot).toISOString());
    if (van != null) params = params.append("van", new Date(van).toISOString());
    if (trainer != null) params = params.append("trainer", trainer);
    if (zaal != null) params = params.append("zaal", zaal);

    console.log(params)

    return this.http.get<trainingsSearchResult[]>(this.backendUrl + "training-search", {params: params})
      .pipe(catchError(e => this.handleError(e)));
  }

  alletrainers() {
    return this.http.get<Trainer[]>(this.backendUrl + "all-trainers")
      .pipe(catchError(e => this.handleError(e)));

  }

  trainerToevoegenAanTraining(trainerId: string, trainingId: string) {

    let params = new HttpParams();
    params.append("training", trainingId);
    const body = {
      "trainer": trainerId,
    }

    return this.http.post<TrainingsRegistratieSearchResult>(this.backendUrl + "add-trainer-to-training?training=" + trainingId, body,
      {params: params})
      .pipe(catchError(e => this.handleError(e)));
  }

  // admin calls --------------------------------------
  statistics() {

    return this.http.get<Statistics>(this.backendAdminUrl + "statistics")
      .pipe(catchError(e => this.handleError(e)));
  }

  alleGebruikers() {
    return this.http.get<GebruikerDetail[]>(this.backendAdminUrl + "all-users")
      .pipe(catchError(e => this.handleError(e)));

  }

  downloadRapport() {
    const url = this.backendAdminUrl + "export/file"
    this.downloadFile(url)
  }

  downloadRapportByBites() {
    return this.http.get(this.backendAdminUrl + "export")
      .pipe(catchError(e => this.handleError(e)));
  }

  upsertTraining(training: Training){
    return this.http.post<trainingsSearchResult>(this.backendAdminUrl + "upsert/training", training)
      .pipe(catchError(e => this.handleError(e)));
  }


  resetPassword(id) {
    return this.http.get<GebruikerDetail>(this.backendAdminUrl + "reset-password/" + id)
      .pipe(catchError(e => this.handleError(e)));
  }

  updateGebruiker(gebruikerDetail: GebruikerDetail) {
    return this.http.post<GebruikerDetail>(this.backendAdminUrl + "update-gebruiker/" + gebruikerDetail.id,
      gebruikerDetail)
      .pipe(catchError(e => this.handleError(e)));
  }

  gebruikerStatistics(id: string) {
    return this.http.get<GebruikerStatistics>(this.backendAdminUrl + "statistics/" + id)
      .pipe(catchError(e => this.handleError(e)));
  }


  private downloadFile(url: string): void {

    this.http.get<Blob>(url, {responseType: 'blob' as 'json', observe: 'response'}).subscribe(
      (response: any) => {
        debugger
        var contentDisposition = response.headers.get('content-disposition');
        var filename = contentDisposition.split(';')[1].split('filename')[1].split('=')[1].trim();
        console.log(filename);

        let dataType = response.type;
        let binaryData = [];
        binaryData.push(response.body);
        let downloadLink = document.createElement('a');
        downloadLink.href = window.URL.createObjectURL(new Blob(binaryData, {type: dataType}));
        if (filename)
          downloadLink.setAttribute('download', filename);
        document.body.appendChild(downloadLink);
        downloadLink.click();
      }
    )
  }

  private handleError(error: HttpErrorResponse, globals: Globals = this.globals) {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong.
      console.error(
        `Backend returned code ${error.status}, ` +
        `body was: ` + JSON.stringify(error));
      if (error.status == 401) {
        // no access -> logout?
        this.loginSerivce.logout()
      } else if (error.status == 403) {

      } else if (error.status == null) {
        //weird background inactive thing
      }
        // else if (error.status == 0) {
        //   //backend offline - net::ERR_CONNECTION_REFUSED
        //   globals.offline = true;
        //   this.offline = true;
        //   this.router.navigate(["/logout"], {queryParams: {error: JSON.stringify(error)}})
      //}
      else {
        this.router.navigate(["/error"], {queryParams: {error: JSON.stringify(error)}})
      }
    }
    // Return an observable with a user-facing error message.
    return throwError(
      'Something bad happened; please try again later.');
  }


}

