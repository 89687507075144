import {Component, Inject, Input, OnInit} from '@angular/core';
import {Trainer, trainingsSearchResult} from "../../common/model";
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from "@angular/material/dialog";
import {BackendService} from "../../common/BackendService";

@Component({
  selector: 'app-list-training',
  templateUrl: './list-training.component.html',
  styleUrls: ['./list-training.component.scss']
})
export class ListTrainingComponent implements OnInit {

  @Input() training: trainingsSearchResult[]
  @Input() searchParams: String[]
  trainers: Trainer[]

  constructor(public dialog: MatDialog, private backend: BackendService) {
    backend.alletrainers()
      .subscribe(r => this.trainers = r)

  }

  ngOnInit(): void {
  }

  trainersAlreadyListed(training: trainingsSearchResult){
    return training.registraties.map(r => r.trainerNaam);
  }

  trainerToevoegen(training: trainingsSearchResult) {

    var alreadyListed: String[] = training.registraties.map(r => r.trainerNaam);
    const dialogRef = this.dialog.open(DialogPopup, {
      width: '250px',
      data: this.trainers.filter(t => !alreadyListed.includes(t.trainerNaamKort)).map(r => r.trainerNaam).sort((a, b) => a.localeCompare(b))
    });

    dialogRef.afterClosed().subscribe(result => {

      if (result == undefined) return;

      var trainer: Trainer =
        this.trainers.find(t => t.trainerNaam == result);

      this.backend.trainerToevoegenAanTraining(trainer.id, training.id)
        .subscribe(registratie =>
          training.registraties.push(registratie)
        )

    });
  }

  trainersToList() {
    return this.trainers;
  }
}

@Component({
  selector: 'dialog-add-trainer',
  template: `<h1 mat-dialog-title>Trainer Toevoegen</h1>
  <div mat-dialog-content>
    <mat-form-field appearance="fill">
      <mat-label>Trainer</mat-label>
      <mat-select [(ngModel)]="selectedTrainer">
        <mat-option *ngFor="let trainer of trainers" [value]="trainer">
          {{trainer}}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>
  <div mat-dialog-actions>
    <button mat-button (click)="onNoClick()">Annuleren</button>
    <button mat-button [mat-dialog-close]="selectedTrainer" cdkFocusInitial>Ok</button>
  </div>`
})
export class DialogPopup {

  selectedTrainer: string;


  constructor(
    public dialogRef: MatDialogRef<DialogPopup>,
    @Inject(MAT_DIALOG_DATA) public trainers: String[]) {
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

}
