import {Component, OnInit} from '@angular/core';
import {BackendService} from "../../common/BackendService";
import {TrainingSearchOptions, trainingsSearchResult} from "../../common/model";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {MatSnackBar} from "@angular/material/snack-bar";

@Component({
  selector: 'app-manage-training',
  templateUrl: './manage-training.component.html',
  styleUrls: ['./manage-training.component.scss']
})
export class ManageTrainingComponent implements OnInit {

  searchOptions: TrainingSearchOptions
  selectedGroep: string;
  selectedZaal: string;
  selectedTrainer: string;
  searchForm: FormGroup;

  queryParams: string[];

  resultaten: trainingsSearchResult[];


  constructor(private backend: BackendService, private formBuilder: FormBuilder, private _snackBar: MatSnackBar,) {
    this.resultaten = [];

    this.searchOptions = {
      groepen: [],
      zaalen: [],
      trainers: []
    }

    backend.getTrainingSearchOptions().subscribe(r => {
      this.searchOptions = r

      if (this.searchOptions.groepen.length == 1) {
        this.selectedGroep = this.searchOptions.groepen[0];
      }
      if (this.searchOptions.trainers.length == 1) {
        this.selectedGroep = this.searchOptions.trainers[0];
      }
      if (this.searchOptions.zaalen.length == 1) {
        this.selectedGroep = this.searchOptions.zaalen[0];
      }
    })
  }


  ngOnInit(): void {
    this.searchForm = this.formBuilder.group({
      groep: ['', Validators.required],
      fromDate: ['',],
      toDate: ['',],
      zaal: ['',],
      trainer: ['',],
    })

    this.searchOptions = {
      groepen: [],
      zaalen: [],
      trainers: []
    }

  }


  search() {
    let groep = this.searchForm.value.groep != undefined ? this.searchForm.value.groep.toString() : null;
    let van = this.searchForm.value.fromDate != "" ? this.searchForm.value.fromDate.toISOString() : null;
    let tot = this.searchForm.value.toDate != "" ? this.searchForm.value.toDate.toISOString() : null;
  //  let zaal = this.searchForm.value.zaal != undefined ? this.searchForm.value.zaal.toString() : null;
    let zaal = null; // disabled;
    let trainer = this.searchForm.value.trainer != undefined ? this.searchForm.value.trainer.toString() : null;

    this.queryParams = [];
    if(groep) this.queryParams.push(groep);
    if(van) this.queryParams.push(" van " + new Date(van).toLocaleDateString());
    if(tot) this.queryParams.push(" tot " + new Date(tot).toLocaleDateString());
    if(trainer) this.queryParams.push(" door "+ trainer);

    this.backend.zoekTrainingen(
      groep, van, tot, zaal, trainer)
      .subscribe(r => {
        this.resultaten = r;

        if(r.length == 0){
          this._snackBar.open("Geen resulaten gevonden...", null, {
            duration: 5000
          })
        }
      })
  }
}
