import {Injectable} from "@angular/core";
import {HttpHandler, HttpHeaders, HttpInterceptor, HttpRequest} from "@angular/common/http";
import {Gebruiker} from "./model";
import {Globals} from "./globalsService";

@Injectable()
export class AuthInterceptor implements HttpInterceptor {


  user: Gebruiker;

  constructor(private globals: Globals) {
  }

  intercept(req: HttpRequest<any>, next: HttpHandler) {
    this.user = JSON.parse(localStorage.getItem('currentUser'));
    if (this.user == null) {
      return next.handle(req);
    }

    // Clone the request and replace the original headers with
    // cloned headers, updated with the authorization.

    let authReq = req.clone({
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': 'http://localhost:4200',
        'Access-Control-Allow-Credentials': 'true',
        Authorization: 'Basic ' + this.user.auth
      })
    });

    // send cloned request with header to the next handler.
    return next.handle(authReq);
  }

}
