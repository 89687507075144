import {Component, OnInit} from '@angular/core';
import {Gebruiker} from "../common/model";

@Component({
  selector: 'app-profiel',
  templateUrl: './profiel.component.html',
  styleUrls: ['./profiel.component.scss']
})
export class ProfielComponent implements OnInit {

  constructor() {
  }

  gebruiker: Gebruiker;

  ngOnInit(): void {
    this.gebruiker = JSON.parse(localStorage.getItem('currentUser'));
  }

}
