<div class="center v-stretch">
  <mat-card class="center  full-width ">
    <mat-card-title>Registreer / reset</mat-card-title>
    <mat-card-subtitle>Opgelet: je kan enkel een login maken op uitnodiging.</mat-card-subtitle>
    <mat-card-content>
      <form class="login" #f="ngForm">
        <!--        <mat-form-field class="login-full-width">-->
        <!--          <mat-label>Voornaam</mat-label>-->
        <!--          <input [(ngModel)]="data.voornaam" matInput [formControl]="voornaamFormControl" [errorStateMatcher]="matcher"-->
        <!--                 placeholder="vb. jonas">-->
        <!--          <mat-error *ngIf="emailFormControl.hasError('required')">-->
        <!--            Voornaam is <strong>verplicht</strong>-->
        <!--          </mat-error>-->
        <!--        </mat-form-field>-->
        <!--        <mat-form-field class="login-full-width">-->
        <!--          <mat-label>Achternaam</mat-label>-->
        <!--          <input [(ngModel)]="data.achternaam" matInput [formControl]="achternaamFormControl" [errorStateMatcher]="matcher"-->
        <!--                 placeholder="vb. Van den Broek">-->
        <!--          <mat-error *ngIf="emailFormControl.hasError('required')">-->
        <!--            Achternaam is <strong>verplicht</strong>-->
        <!--          </mat-error>-->
        <!--        </mat-form-field>-->

        <mat-form-field class="full-width">
          <mat-label>Registreer Code</mat-label>
          <input [(ngModel)]="data.code" matInput [formControl]="codeFormControl" [errorStateMatcher]="matcher"
                 placeholder="vb. Van den Broek">
          <mat-error *ngIf="codeFormControl.hasError('required')">
            Code is <strong>verplicht</strong>. Heb je er geen? Vraag er dan een aan beheerder!
          </mat-error>
        </mat-form-field>

        <mat-form-field class="full-width">
          <mat-label>Email</mat-label>
          <input [(ngModel)]="data.email" matInput [formControl]="emailFormControl" [errorStateMatcher]="matcher"
                 placeholder="vb. jonas@d-en-v.be">
          <mat-error *ngIf="emailFormControl.hasError('email') && !emailFormControl.hasError('required')">
            Een geldig email adres ingeven aub
          </mat-error>
          <mat-error *ngIf="emailFormControl.hasError('required')">
            Email is <strong>verplicht</strong>
          </mat-error>
        </mat-form-field>


        <mat-form-field class="full-width">
          <mat-label>Nieuw wachtwoord</mat-label>
          <input [(ngModel)]="data.password" matInput [type]="hide ? 'password' : 'text'"
                 [formControl]="passFormControl"
                 [errorStateMatcher]="matcher">
          <button mat-icon-button matSuffix (click)="hide = !hide" [attr.aria-label]="'Hide password'"
                  [attr.aria-pressed]="hide">
            <mat-icon>{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
          </button>
          <mat-error *ngIf="emailFormControl.hasError('required')">
            wachtwoord is <strong>verplicht</strong>
          </mat-error>
          <mat-error *ngIf="emailFormControl.hasError('minlength')">
            minimum lengte voor wachtwoord is <strong>5 karakters</strong>
          </mat-error>
        </mat-form-field>

      </form>
    </mat-card-content>
    <mat-card-actions class="actions">
      <button mat-button [routerLink]="['/login']"  [queryParams]="{'email': data.email}">Ik heb al een login.</button>
      <button mat-raised-button (click)="submit(f)"  [disabled]="!f.valid" color="primary">Registreer</button>
    </mat-card-actions>
  </mat-card>
</div>
