import {Component, OnInit} from '@angular/core';
import {BackendService} from "../common/BackendService";
import {Training, TrainingStatus} from "../common/model";
import {MatSnackBar} from "@angular/material/snack-bar";
import {ActivatedRoute, Router} from "@angular/router";
import {Globals} from "../common/globalsService";

@Component({
  selector: 'app-training-logger',
  templateUrl: './training-logger.component.html',
  styleUrls: ['./training-logger.component.scss']
})
export class TrainingLoggerComponent implements OnInit {

  listView: boolean = false;

  trainingList: Training[] = [];
  actieveTrainingIndex: number = 0;
  actieveTraining: Training;
  tellers: {
    totaal: number,
    bevestigd: number,
    open: number
  }

  constructor(private backend: BackendService,
              private _snackBar: MatSnackBar,
              private route: ActivatedRoute,
              public globals: Globals,
              private router:Router) {
    this.tellers = {totaal: 0, bevestigd: 0, open: 0};

    this.listView = this.route.snapshot.url.length >= 2 && this.route.snapshot.url[1].path == "overzicht";

  }

  ngOnInit(): void {
    this.backend.loadTrainingList().subscribe(v => {
      this.trainingList = v

      if (this.trainingList != null && this.trainingList.length != 0) {
        this.goToLastToConfirm()
      } else {
        this.trainingList = [];
        this.actieveTraining = null;

        // if (this.globals.adminPanel) {
        //   this.router.navigate(["/administratie/dashboard"])
        // }
      }
      this.updateTeller();
    })


  }

  vorige() {
    if (this.hasVorige()) {
      this.actieveTraining = this.trainingList[--this.actieveTrainingIndex]
    }
  }

  volgend() {
    if (this.hasVolgende()) {
      this.actieveTraining = this.trainingList[++this.actieveTrainingIndex]
    }
  }

  toekomstig(): boolean {
    if (this.actieveTraining == null) return false;

    const date: Date = new Date();
    date.setHours(new Date().getHours() + 1)
    return new Date(this.actieveTraining.beginUur) > date;
  }

  hasVolgende() {
    return this.trainingList[this.actieveTrainingIndex + 1] != null;
  }

  hasVorige() {
    return this.trainingList[this.actieveTrainingIndex - 1] != null;
  }

  bevestig(aanwezig: boolean) {
    this.bevestigById(aanwezig, this.actieveTraining.id);
  }

  bevestigById(aanwezig: boolean, trainingid: string) {
    this.backend.bevestigTraining(this.actieveTraining.id, aanwezig)
      .subscribe(r => {
        this.updateAndContinue(r)
        this._snackBar.open('Aanwezigheid verwerkt. ' + (this.tellers.open != 0 ? 'Nog ' + this.tellers.open + ' te gaan.' : ''), null, {
          duration: 5000
        })
      });
  }

  private updateAndContinue(t: Training) {
    this.actieveTraining = t
    this.trainingList[this.actieveTrainingIndex] = t
    this.updateTeller()

    if (this.hasVolgende()) {
      this.volgend()
    }
    if (this.tellers.open == 0) {
      this._snackBar.open('Alle registraties volledig! <br/> Goed bezig! ', null, {
        duration: 10000
      })
    }
  }

  private updateTeller() {

    this.tellers.totaal = this.trainingList.length;
    this.tellers.bevestigd = this.trainingList
      .filter(t => t.locked == false)
      .filter(t => t.status != TrainingStatus.OPEN).length;

    this.tellers.open = this.trainingList
      .filter(t => t.locked == false)
      .filter(t => new Date(t.beginUur) < new Date())
      .filter(t => t.status.toString() == TrainingStatus.OPEN).length;
  }

  reopen() {
    if (this.toekomstig()) {
      this.bevestig(true);
    } else {
      this.actieveTraining.status = TrainingStatus.OPEN;
    }
  }

  goToLastToConfirm() {
    this.actieveTrainingIndex = 0;
    this.actieveTraining = this.trainingList[this.actieveTrainingIndex]

    while ((this.actieveTraining.status != TrainingStatus.OPEN
      ||  this.actieveTraining.locked == true) &&
      this.actieveTrainingIndex < this.trainingList.length - 1) {
      this.volgend()
    }
  }


}
