<!--<h3>Login</h3>
<form #f="ngForm" (ngSubmit)="onSubmit(f)" novalidate>
  <input [(ngModel)]="email" name="email" ngModel required>
  <input  name="password" ngModel required>
  <button>Submit</button>
</form>-->
<div class="center v-stretch full-width">
  <mat-card class="center full-width ">
    <mat-card-title>Login</mat-card-title>
    <mat-card-subtitle *ngIf="errorFeedback"> {{errorFeedback}} </mat-card-subtitle>
    <mat-card-content>
      <form  #f="ngForm" (keydown)="keyDownFunction($event, f)">
        <mat-form-field class="full-width">
          <mat-label>Email</mat-label>
          <input [(ngModel)]="email" matInput [formControl]="emailFormControl" [errorStateMatcher]="matcher"
                 placeholder="Ex. jonas@d-en-v.be">

          <mat-error *ngIf="emailFormControl.hasError('email') && !emailFormControl.hasError('required')">
            Een geldig email adres ingeven aub
          </mat-error>
          <mat-error *ngIf="emailFormControl.hasError('required')">
            Email is <strong>verplicht</strong>
          </mat-error>
        </mat-form-field>


        <mat-form-field class="full-width">
          <mat-label>wachtwoord</mat-label>
          <input [(ngModel)]="password" matInput type="password" [formControl]="passFormControl"
                 [errorStateMatcher]="matcher">
          <mat-error *ngIf="passFormControl.hasError('wachtwoord') && !passFormControl.hasError('required')">
            ongeldig wachtwoord
          </mat-error>
          <mat-error *ngIf="emailFormControl.hasError('required')">
            wachtwoord is <strong>verplicht</strong>
          </mat-error>
        </mat-form-field>

      </form>
    </mat-card-content>
    <mat-card-actions class="actions">
      <button mat-button [routerLink]="['/registreer']" [queryParams]="{'email': email}">Nog geen login? Hier registreren.</button>
      <button mat-raised-button (click)="onSubmit(f)" color="primary">Login</button>
    </mat-card-actions>
  </mat-card>
</div>
