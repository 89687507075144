import {Component, Inject, OnInit} from '@angular/core';
import {BackendService} from "../../common/BackendService";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {Training, TrainingSearchOptions, trainingsSearchResult} from "../../common/model";
import {MatSnackBar} from "@angular/material/snack-bar";
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from "@angular/material/dialog";
import {formatDate} from "@angular/common";

@Component({
  selector: 'app-admin-training',
  templateUrl: './admin-training.component.html',
  styleUrls: ['./admin-training.component.scss', "/src/app/common/admin.scss"]
})
export class AdminTrainingComponent implements OnInit {

  searchOptions: TrainingSearchOptions
  selectedGroep: string;
  selectedZaal: string;
  selectedTrainer: string;
  focusedTraining: string;

  searchForm: FormGroup;

  queryParams: string[];

  resultaten: trainingsSearchResult[];

  constructor(private backend: BackendService, private formBuilder: FormBuilder, private _snackBar: MatSnackBar, public dialog: MatDialog,) {
    this.resultaten = [];
    this.queryParams = [];
    this.searchOptions = {
      groepen: [],
      zaalen: [],
      trainers: []
    }

    backend.getTrainingSearchOptions().subscribe(r => {
      this.searchOptions = r

      if (this.searchOptions.groepen.length == 1) {
        this.selectedGroep = this.searchOptions.groepen[0];
      }
      if (this.searchOptions.trainers.length == 1) {
        this.selectedGroep = this.searchOptions.trainers[0];
      }
      if (this.searchOptions.zaalen.length == 1) {
        this.selectedGroep = this.searchOptions.zaalen[0];
      }
    })
  }

  ngOnInit(): void {
    this.searchForm = this.formBuilder.group({
      groep: ['', Validators.required],
      datum: ['',],
      trainer: ['',],
    })

    this.searchOptions = {
      groepen: [],
      zaalen: [],
      trainers: []
    }

  }

  search() {
    let groep = this.searchForm.value.groep != undefined && this.searchForm.value.groep != '' ? this.searchForm.value.groep.toString() : null;
    let trainer = this.searchForm.value.trainer != undefined && this.searchForm.value.trainer != '' ? this.searchForm.value.trainer.toString() : null;
    let datum = this.searchForm.value.datum != null && this.searchForm.value.datum != "" ? this.searchForm.value.datum.toISOString() : null;

    this.backend.zoekTrainingen(
      groep, datum, datum, null, trainer)
      .subscribe(r => {
        this.resultaten = r;

        if (r.length == 0) {
          this._snackBar.open("Geen resulaten gevonden...", null, {
            duration: 5000
          })
        }
      })
  }

  addTraining() {
    const dialogRef = this.dialog.open(AdminDialogEditTraining, {
      width: '500px',
      data: {
        training: new Training(),
        groepen: this.searchOptions.groepen
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result != undefined) {
        this.backend.upsertTraining(result).subscribe(newTraining => {
          this.searchForm.setValue({
            groep: newTraining.groep,
            trainer: '',
            datum: new Date(("" + newTraining.beginUur).split('T')[0])
          });
          this.search();
          this.focusedTraining = newTraining.id;
        });

      }
    });
  }

}

@Component({
  selector: 'dialog-edit-training',
  template: `
    <form [formGroup]="upsertTrainingForm" #f="ngForm">
      <h1 mat-dialog-title>Training</h1>
      <div mat-dialog-content>
        <mat-form-field class="full-width">
          <mat-label>Groep</mat-label>
          <mat-select formControlName="groep" [(ngModel)]="this.training.groepName">
            <mat-option *ngFor="let groep of groepen" [value]="groep">
              {{groep}}
            </mat-option>
          </mat-select>
        </mat-form-field>

        <!--      <mat-form-field class="full-width">-->
        <!--        <mat-label>start</mat-label>-->
        <!--        <input matInput [matDatepicker]="picker_start" formControlName="start" >-->
        <!--        <mat-datepicker-toggle matSuffix [for]="picker_start"></mat-datepicker-toggle>-->
        <!--        <mat-datepicker #picker_start></mat-datepicker>-->
        <!--      </mat-form-field>-->
        <mat-form-field class="full-width">
          <mat-label>start</mat-label>
          <input #start matInput type="datetime-local" formControlName="start" (ngModelChange)="setEinde()"
                 [(ngModel)]="training.beginUur">
        </mat-form-field>
        <mat-form-field class="full-width">
          <mat-label>einde</mat-label>
          <input #einde matInput type="datetime-local" formControlName="einde" [(ngModel)]="training.eindUur ">
        </mat-form-field>
      </div>
      <div mat-dialog-actions class="evenly-horizontal-spaced" align="end">
        <button mat-button (click)="onNoClick()">Annuleren</button>
        <button *ngIf="training.id != ''" mat-raised-button color="warn" [mat-dialog-close]="training">
          verwijderen
        </button>
        <button *ngIf="training.id != ''" mat-raised-button color="primary"
                [disabled]="!upsertTrainingForm.valid"
                [mat-dialog-close]="training"
                cdkFocusInitial>
          Aanpassen
        </button>
        <button *ngIf="training.id == ''" mat-raised-button color="primary"
                [disabled]="!upsertTrainingForm.valid"
                [mat-dialog-close]="training"
                cdkFocusInitial>
          Toevoegen
        </button>
      </div>
    </form>
  `
})
export class AdminDialogEditTraining {
  upsertTrainingForm: FormGroup;
  training: Training;
  public groepen: any[]


  constructor(
    public dialogRef: MatDialogRef<AdminDialogEditTraining>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private formBuilder: FormBuilder
  ) {
    this.upsertTrainingForm = this.formBuilder.group({
      groep: ['', Validators.required],
      start: ['', Validators.required],
      einde: ['', Validators.required],
    })

    if (this.data.training.id != null) {
      //edit mode
      this.training = data.training;
      this.groepen = [data.training.groep]

    } else {
      //create mode
      this.training = new Training();
      this.training.id = ""
      this.groepen = [""].concat(data.groepen)
    }

  }

  setEinde(): void {
    const start: Date = new Date(this.upsertTrainingForm.get('start').value);
    start.setHours(start.getHours() + 2)
    this.upsertTrainingForm.get('einde').setValue(formatDate(start, "yyyy-MM-ddTHH:mm", 'en-UK'))
  }

  onNoClick(): void {
    this.dialogRef.close();
  }
}
