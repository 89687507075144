import {Component, Input, OnInit} from '@angular/core';
import {Trainer} from "../../common/model";
import {DialogPopup} from "../../searchAndModTraining/list-training/list-training.component";
import {BackendService} from "../../common/BackendService";
import {MatSnackBar} from "@angular/material/snack-bar";
import {MatDialog} from "@angular/material/dialog";
import {ActivatedRoute} from "@angular/router";

@Component({
  selector: 'add-trainer',
  templateUrl: './add-trainer.component.html',
  styleUrls: ['./add-trainer.component.scss']
})
export class AddTrainerComponent implements OnInit {

  @Input() trainersToList: Trainer[];
  @Input() trainersToHide: String[];
  @Input() trainingId: string;

  constructor(private backend: BackendService, private _snackBar: MatSnackBar, public dialog: MatDialog, private route: ActivatedRoute) {
  }

  ngOnInit(): void {

  }

  trainerToevoegen() {

    var alreadyListed: String[] = this.trainersToHide; //.map(r => r.trainerNaam);
    const dialogRef = this.dialog.open(DialogPopup, {
      width: '250px',
      data: this.trainersToList.filter(t => !alreadyListed.includes(t.trainerNaamKort))
        .map(r => r.trainerNaam).sort((a, b) => a.localeCompare(b))
    });

    dialogRef.afterClosed().subscribe(result => {

      if (result == undefined) return;

      var trainer: Trainer =
        this.trainersToList.find(t => t.trainerNaam == result);

      this.backend.trainerToevoegenAanTraining(trainer.id, this.trainingId)
        .subscribe(registratie =>
            console.log(registratie)
          //   this.trainersToHide.push(this.trainersToList.find(t => registratie.trainerNaam))
        )

    });
  }

  addTrainer() {

  }
}
