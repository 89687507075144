import {Injectable} from "@angular/core";
import {BehaviorSubject} from "rxjs";
import {Gebruiker} from "./model";

@Injectable()
export class Globals {
  showMenu: boolean = false;
  offline: boolean = false;
  menuSearch: boolean = false;
  adminPanel: boolean = false;
  loading: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  private user: Gebruiker;

  constructor() {
    console.log("new Globals")

    this.user = JSON.parse(localStorage.getItem('currentUser'));

    if (this.user != null) {
      this.menuSearch = this.user.admin || this.user.hoofdTrainer;
      this.adminPanel = this.user.admin;
    }
  }
}
