import {Component, OnInit} from '@angular/core';
import {FormControl, FormGroupDirective, NgForm, Validators} from "@angular/forms";
import {ActivatedRoute, Router} from "@angular/router";
import {ErrorStateMatcher} from "@angular/material/core";
import {BackendService} from "../common/BackendService";
import {MatSnackBar} from "@angular/material/snack-bar";

/** Error when invalid control is dirty, touched, or submitted. */
export class MyRegisterErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    const isSubmitted = form && form.submitted;
    return !!(control && control.invalid && (control.dirty || control.touched || isSubmitted));
  }
}

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss']
})
export class RegisterComponent implements OnInit {

  data: {
    email: string,
    voornaam: string,
    achternaam: string,
    code: string,
    password: string,
  }
  hide: boolean = true;
  errorFeedback: string;
  matcher = new MyRegisterErrorStateMatcher();

  voornaamFormControl = new FormControl('', [
    Validators.required,
    Validators.minLength(3)
  ]);

  achternaamFormControl = new FormControl('', [
    Validators.required,
    Validators.minLength(15)
  ]);

  codeFormControl = new FormControl('', [
    Validators.required,
    Validators.minLength(3)
  ]);

  emailFormControl = new FormControl('', [
    Validators.required,
    Validators.email,
  ]);

  passFormControl = new FormControl('', [
    Validators.required,
    Validators.minLength(5)
  ]);

  constructor(private route: ActivatedRoute, private backend: BackendService, private _snackBar: MatSnackBar, private router: Router) {
    this.data = {email: null, voornaam: null, achternaam: null, password: null, code: null};
    this.data.email = this.route.snapshot.queryParamMap.get('email');
    this.data.code = this.route.snapshot.queryParamMap.get('code');

  }

  ngOnInit(): void {
  }

  submit(f: NgForm) {
    this.emailFormControl.markAsTouched()
    this.passFormControl.markAsTouched()
    this.codeFormControl.markAsTouched()
    // this.voornaamFormControl.markAsTouched()
    // this.achternaamFormControl.markAsTouched()
// console.log("REGISREER MIJ!!")
    if (this.matcher.isErrorState(this.passFormControl, f) ||
      this.matcher.isErrorState(this.emailFormControl, f) ||
      this.matcher.isErrorState(this.codeFormControl, f)) {
      return;
    }
    this.backend.registreer( this.data.code, this.data.email, this.data.password)
      .subscribe(r => {
        this._snackBar.open("Registratie gelukt! nu even inloggen!", null, {
          duration: 5000
        })
        this.router.navigate(["login"], {queryParams: {email: r.email}})
      }, err => {

        if (err.status == 400) {
          this._snackBar.open("Registratie gelukt! nu even inloggen!", null, {
            duration: 5000
          })
        }
      });
  }
}
