<div class="center v-stretch full-width">
  <mat-card class="center  full-width">
    <mat-card-title>
      <span class="material-icons">error</span>Woepsie...
    </mat-card-title>
    <mat-card-subtitle>Er is iets mis gegaan...</mat-card-subtitle>
    <img class="image" src="../../assets/sad_kitten.png" alt="sad kitten"/>
    <mat-card-content>
      <p>Probeer je opnieuw aan te melden of contacteer een beheerder en meld dit probleem.</p>
      <a [routerLink]="['/login']" [queryParams]="{email: gebruiker.email}">
        Opniew aanmelden
      </a>
      <p *ngIf="gebruiker.admin">{{error}}</p>
    </mat-card-content>
  </mat-card>

</div>
