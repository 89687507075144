<form class="searchbar">
  <mat-form-field class="full-width">
    <mat-label>Zoek gebruiker</mat-label>
    <input type="text" matInput [formControl]="myControl" [matAutocomplete]="auto">
    <mat-autocomplete #auto="matAutocomplete">
<!--      [displayWith]="displayFn"-->
      <mat-option *ngFor="let option of filteredOptions | async" [value]="option.id">
        {{option.voornaam}} {{option.achternaam}}
      </mat-option>
    </mat-autocomplete>
  </mat-form-field>
</form>

<!--<div class="admin-content">-->

<!--  froms here-->
<!--  <mat-card>Simple card</mat-card>-->
<!--</div>-->
<mat-card>
  <mat-card-title>Gebruiker</mat-card-title>
  <p *ngIf="noOptions"> Geen gebruikers gevonden </p>
  <div *ngFor="let option of filteredOptions | async" class="split" >
    <span>{{option.voornaam}} {{option.nickname != null? "(" +option.nickname + ")": ""}} {{option.achternaam}} </span>
    <button  mat-icon-button color="primary" aria-label="Gebruiker wijzigen" (click)="openEditUserDialog(option.id)"><mat-icon>build</mat-icon></button>
  </div>
</mat-card>
