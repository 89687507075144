<h2 mat-dialog-title>{{gebruiker.voornaam}} {{gebruiker.achternaam}}</h2>
<mat-dialog-content class="mat-typography">
  <form class="login" #f="ngForm">
    <mat-form-field class="full-width">
      <mat-label>Voornaam</mat-label>
      <input [(ngModel)]="gebruiker.voornaam" name="voornaam" matInput placeholder="Ex. jonas@d-en-v.be">
    </mat-form-field>
    <br>
    <mat-form-field class="full-width">
      <mat-label>Achternaam</mat-label>
      <input [(ngModel)]="gebruiker.achternaam" name="achternaam" matInput>
    </mat-form-field>
    <br>
    <mat-form-field class="full-width">
      <mat-label>Email en login</mat-label>
      <input [(ngModel)]="gebruiker.email" [formControl]="emailFormControl" name="email" matInput>
    </mat-form-field>
    <br>
    <mat-form-field class="full-width">
      <mat-label>Bijnaam</mat-label>
      <input [(ngModel)]="gebruiker.nickname" name="nickname" matInput>
    </mat-form-field>
    <br>
    <mat-form-field class="full-width">
      <mat-label>hoofdtrainer van</mat-label>
      <input *ngIf="gebruiker.hoofdTrainerVanGroep.length != 0"
             [disabled]="true" value="{{gebruiker.hoofdTrainerVanGroep.join(', ')}}" name="hoofdtrainer" matInput>
      <input *ngIf="gebruiker.hoofdTrainerVanGroep.length == 0"
             [disabled]="true" value="geen groep" name="hoofdtrainer" matInput>
    </mat-form-field>

    <mat-checkbox class="example-margin" name="admin"
                  matTooltip="Kan in de Administratie console."
                  [(ngModel)]="gebruiker.beheerder">
      Administrator
    </mat-checkbox>
    <br>
    <mat-checkbox class="example-margin" name="nonActief"
                  matTooltip="Is niet meer actief in het systeem. Kan niet meer aanloggen."
                  [(ngModel)]="gebruiker.nonActief">
      NonActief
    </mat-checkbox>
    <br>
    <mat-checkbox class="example-margin" name="trainer"
                  matTooltip="Kan worden opgegeven als Training bij vervangingen."
                  [(ngModel)]="gebruiker.trainer">
      Trainer
    </mat-checkbox>
  </form>
  <br>
  <button *ngIf="gebruiker.resetPasswordLink == null"
          mat-raised-button color="warn"
          (click)="resetPassword()">Reset passwoord
  </button>
  <mat-form-field *ngIf="gebruiker.resetPasswordLink != null" class="full-width">
    <mat-label>reset password link (2 weken geldig)</mat-label>
    <input [disabled]="true" value="{{gebruiker.resetPasswordLink}}" matInput>
  </mat-form-field>
  <h3></h3>

  <mat-divider inset></mat-divider>


  <h3>Gebruiker statistieken</h3>
  <p>
   Trainingen totaal: {{gebruikerStats.totaalTrainingen}} <br>
   Trainingen tot vandaag: {{gebruikerStats.totaalTrainingenTotVandaag}} <br>
   Trainingen niet ingevuld tot vandaag: {{gebruikerStats.totaalOpenTrainingenTotVandaag}} <br>
    Laatste registratie {{gebruikerStats.laatsteRegistratie | date: 'd/MM/yyyy'}}
  </p>

</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-button mat-dialog-close>Annulleren</button>
  <button mat-button [mat-dialog-close]="gebruiker" cdkFocusInitial>Opslaan</button>
</mat-dialog-actions>

