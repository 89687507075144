<mat-progress-bar *ngIf="(globals.loading | async )" mode="query" class="query-bar progress-bar"></mat-progress-bar>
<!--<p style="color: white">loading is: {{(globals.loading.value)}}</p>-->
<div class="v-stretch content">
  <!--            <button hidden mat-button mat-raised-button class="menu-button" (click)="sidenav.toggle()">-->
  <!--              <mat-icon>menu</mat-icon>-->
  <!--            </button>-->
  <div *ngIf="globals.offline" class="offline">
    <mat-icon class="blink_me">sync_problem</mat-icon>
    <span> backend is offline</span></div>

  <router-outlet>

  </router-outlet>
  <br>
  <div class="footer-menu" *ngIf="globals.showMenu">
    <button mat-button [routerLink]="['/log']">
      <mat-icon>query_builder</mat-icon>
    </button>
    <button mat-button [routerLink]="['/log/overzicht']">
      <mat-icon>history</mat-icon>
    </button>
    <!--        <mat-divider [vertical]="true"></mat-divider>-->
    <button mat-button [routerLink]="['/profiel']">
      <mat-icon>person</mat-icon>
    </button>
    <!--        <mat-divider [vertical]="true"></mat-divider>-->
    <button *ngIf="globals.menuSearch" mat-button [routerLink]="['/beheer/trainingen']">
      <mat-icon>search</mat-icon>
    </button>
    <button *ngIf="globals.adminPanel" mat-button [routerLink]="['/administratie/dashboard']">
      <mat-icon>settings_applications</mat-icon>
    </button>

    <!--        <button  mat-button (click)="toggleSearch()">-->
    <!--          <mat-icon>eject</mat-icon>-->
    <!--        </button>-->
  </div>
  <div class="watermark">
    <span>by <br> Toon Carmen</span>
  </div>
</div>


<!--<mat-sidenav-container class=" v-stretch d-v-background">-->
<!--  <mat-sidenav #sidenav mode="over">-->
<!--    <mat-list>-->
<!--      <mat-list-item>Mijn trainingen</mat-list-item>-->
<!--      <mat-divider></mat-divider>-->
<!--      <mat-list-item>Mijn profiel</mat-list-item>-->
<!--      <mat-divider></mat-divider>-->
<!--      <mat-list-item>Trainingen beheren</mat-list-item>-->
<!--    </mat-list>-->
<!--  </mat-sidenav>-->
<!--  <mat-sidenav-content>-->
<!--  </mat-sidenav-content>-->
<!--</mat-sidenav-container>-->

