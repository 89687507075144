<div>
  <mat-card class=" full-width fixedHeight">
    <mat-card-title>Zoek resultaten</mat-card-title>
    <mat-card-subtitle>{{searchParams.join()}}</mat-card-subtitle>
    <mat-card-content>
      <mat-accordion>

        <mat-expansion-panel *ngFor="let tr of training" hideToggle>
          <mat-expansion-panel-header>
            <mat-panel-title>
              {{tr.groep}}
            </mat-panel-title>
            <mat-panel-description>
<!--              {{tr.zaal}} <br>-->
              {{tr.beginUur | parseDag }} {{tr.beginUur | date: 'd/MM/yyyy'}} <br/>  {{tr.beginUur | date: 'HH:mm'}} - {{tr.eindUur | date: 'HH:mm'}}
            </mat-panel-description>
          </mat-expansion-panel-header>
          <p>Trainers:
            <button mat-flat-button *ngFor="let reg of tr.registraties">
              {{reg.trainerNaam}}
              <mat-icon *ngIf="reg.status == 'OPEN'">query_builder</mat-icon>
              <mat-icon *ngIf="reg.status == 'AANWEZIG'">check</mat-icon>
              <mat-icon *ngIf="reg.status == 'AFWEZIG'">cancel</mat-icon>
            </button>
            <button mat-flat-button (click)="trainerToevoegen(tr)">
              <mat-icon>person_add</mat-icon>
            </button>
<!--            <add-trainer-->
<!--              [trainersToHide]="trainersAlreadyListed(tr)"-->
<!--              [trainingId]="tr.id"-->
<!--              [trainersToList]="trainersToList()"-->
<!--            ></add-trainer>-->
          </p>
        </mat-expansion-panel>
      </mat-accordion>

    </mat-card-content>
  </mat-card>
</div>
