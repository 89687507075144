<mat-card>
  <mat-card-title>Overzicht</mat-card-title>
  <mat-list role="list">
    <mat-list-item role="listitem">Trainers totaal: {{statistics.totalTrainers}}</mat-list-item>
    <mat-list-item role="listitem">Groepen totaal: {{statistics.totalGroeps}}</mat-list-item>
    <mat-list-item role="listitem">Trainingen totaal:  {{statistics.totalTraining}}
    </mat-list-item>
  </mat-list>
</mat-card>
<br>
<mat-card>
  <mat-card-title>Registraties</mat-card-title>

  <mat-list role="list" class="data">
    <mat-list-item role="listitem"><span>Totaal:</span> <span> {{statistics.registraties.total}}</span></mat-list-item>
    <mat-list-item role="listitem"><span>Open tot vandaag:</span><span>  {{statistics.registraties.openTotNu}} </span>
      <mat-progress-bar mode="determinate"
                        value="{{relative.openTotNu}}"></mat-progress-bar>
    </mat-list-item>
    <mat-list-item role="listitem">
      <span>Open in toekomst:</span><span>  {{statistics.registraties.openInToekomst}} </span>
      <mat-progress-bar mode="determinate" value="{{relative.openInToekomst}}"></mat-progress-bar>
    </mat-list-item>
    <mat-list-item role="listitem"><span>Open totaal:</span><span>  {{statistics.registraties.open}} </span>
      <mat-progress-bar mode="determinate" value="{{relative.open}}"></mat-progress-bar>
    </mat-list-item>
    <mat-list-item role="listitem"><span>Op aanwezig gezet:</span><span>  {{statistics.registraties.aanwezig}} </span>
      <mat-progress-bar mode="determinate" value="{{relative.aanwezig}}"></mat-progress-bar>
    </mat-list-item>
    <mat-list-item role="listitem"><span>Op afwezig gezet:</span><span>  {{statistics.registraties.afwezig}} </span>
      <mat-progress-bar mode="determinate" value="{{relative.afwezig}}"></mat-progress-bar>
    </mat-list-item>
    <mat-list-item role="listitem"><span>Op slot: </span><span> {{statistics.registraties.locked}}</span>
      <mat-progress-bar mode="determinate" value="{{relative.locked}}"></mat-progress-bar>
    </mat-list-item>
  </mat-list>
</mat-card>
<br>
<mat-card>
  <mat-card-title>Download</mat-card-title>
  <mat-card-content>
    <p>Excel Downloaden. Alle registraties, trainingen en gebruikers als ze gekend zijn in het systeem.</p>
    <p>Dit is de basis voor het berekeningen van de vergoedingen/compensaties voor de trainers.</p>
    <p>Dit document bevat ook alle gebuikers en hun 'rechten' in de app. (wie administrator is, wie verantwoordelijke
      per groep is)</p>
  </mat-card-content>
  <mat-divider inset></mat-divider>
  <mat-card-actions  align="end">
    <button mat-raised-button (click)="download()" mat-button>Download</button>
  </mat-card-actions>
</mat-card>
<br>
<mat-card>
  <mat-card-title>Upload</mat-card-title>
  <mat-card-content>
    <p>Excel uploaden. Een upload volgens de template om data op te laden naar het systeem</p>
  </mat-card-content>
  <mat-divider inset></mat-divider>
  <mat-card-actions  align="end">
    <button mat-raised-button (click)="upload()" mat-button>Upload</button>
  </mat-card-actions>
</mat-card>
<br>
