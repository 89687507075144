import {Component, OnInit} from '@angular/core';
import {BackendService} from 'src/app/common/BackendService';
import {Statistics} from 'src/app/common/adminModel';

@Component({
  selector: 'app-admin-dashboard',
  templateUrl: './admin-dashboard.component.html',
  styleUrls: ['./admin-dashboard.component.scss']
})
export class AdminDashboardComponent implements OnInit {

  statistics: Statistics;
  relative: RelativeNumbers;

  constructor(private backend: BackendService) {
    this.relative = new RelativeNumbers();

    this.relative.locked = 0;
    this.relative.openTotNu = 0;
    this.relative.openInToekomst = 0;
    this.relative.open = 0;
    this.relative.aanwezig = 0;
    this.relative.afwezig = 0;
  }

  ngOnInit(): void {
    this.backend.statistics().subscribe(stats => {
        this.statistics = stats;
        this.relative.locked = (stats.registraties.locked / stats.registraties.total) * 100;
        this.relative.openTotNu = (stats.registraties.openTotNu / stats.registraties.total) * 100;
        this.relative.openInToekomst = (stats.registraties.openInToekomst / stats.registraties.total) * 100;
        this.relative.open = (stats.registraties.open / stats.registraties.total) * 100;
        this.relative.aanwezig = (stats.registraties.aanwezig / stats.registraties.total) * 100;
        this.relative.afwezig = (stats.registraties.afwezig / stats.registraties.total) * 100;
      }
    );
  }

  download() {
    console.log("download")
    this.backend.downloadRapport();
  }

  upload() {

  }

}

export class RelativeNumbers {
  locked: number;
  openTotNu: number;
  openInToekomst: number;
  open: number;
  aanwezig: number;
  afwezig: number;
}

