<mat-card  class="searchbar evenly-horizontal-spaced">
  <form [formGroup]="searchForm" #f="ngForm" class="evenly-horizontal-spaced full-width">
    <mat-form-field>
      <mat-label>Groep</mat-label>
      <mat-select formControlName="groep" (selectionChange)="search()">
        <mat-option *ngIf="searchOptions.groepen.length > 1">Alle</mat-option>
        <mat-option *ngFor="let groep of searchOptions.groepen" [value]="groep">
          {{groep}}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field>
      <mat-label>Trainer</mat-label>
      <mat-select formControlName="trainer" (selectionChange)="search()">
        <mat-option *ngIf="searchOptions.trainers.length > 1">Alle</mat-option>
        <mat-option *ngFor="let trainer of searchOptions.trainers" [value]="trainer">
          {{trainer}}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field>
      <mat-label>datum</mat-label>
      <input matInput [matDatepicker]="picker" formControlName="datum" (dateChange)="search()">
      <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
      <mat-datepicker #picker></mat-datepicker>
    </mat-form-field>
  </form>

  <button mat-mini-fab color="primary" aria-label="Training toevoegen" style="margin-top: 8px;" (click)="addTraining()">
    <mat-icon>add</mat-icon>
  </button>
</mat-card>
<!--<div class="admin-content">-->

<!--  froms here-->
<!--  <mat-card>Simple card</mat-card>-->
<!--</div>-->
<mat-card>
  <mat-label *ngIf="resultaten.length == 0">Geef een zoek parameter om een training te vinden.</mat-label>
  <app-admin-list-training *ngIf="resultaten.length != 0"
                           [training]="resultaten"
                           [focusedTraining]="focusedTraining"
  ></app-admin-list-training>
</mat-card>
