import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from '@angular/router';
import {Globals} from "./globalsService";
import {Gebruiker} from "./model";

@Injectable()
export class AuthGuardAdmin implements CanActivate {
  private user: Gebruiker;

  constructor(private router: Router, private globals: Globals) {
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    if (localStorage.getItem('currentUser')) {
      // logged in so return true
      this.user = JSON.parse(localStorage.getItem('currentUser'));
      if (this.user.admin) {
        console.log('Welcome admin');
        this.globals.showMenu = false;
        return true;
      }
      console.log('Admin rejected');
      this.globals.showMenu = true;
      this.router.navigate(['/log'], {queryParams: {returnUrl: state.url}});
      return false; // not admin, just a user
    }

    console.log('Please log on!');
    this.globals.showMenu = false;
    // not logged in so redirect to login page with the return url
    this.router.navigate(['/login'], {queryParams: {returnUrl: state.url}});
    return false;
  }
}
