<div class="center v-stretch full-width">
  <mat-card class="center  full-width">
    <mat-card-title>Mijn Profiel</mat-card-title>
    <mat-card-content>
      <form class="login" #f="ngForm">
        <mat-form-field class="full-width">
          <mat-label>Voornaam</mat-label>
          <input [(ngModel)]="gebruiker.voornaam" name="voornaam" matInput placeholder="Ex. jonas@d-en-v.be">
        </mat-form-field>
        <br>
        <mat-form-field class="full-width">
          <mat-label>Achternaam</mat-label>
          <input [(ngModel)]="gebruiker.achternaam" name="achternaam" matInput>
        </mat-form-field>
        <br>
        <mat-form-field class="full-width">
          <mat-label>Email</mat-label>
          <input [(ngModel)]="gebruiker.email" name="email" matInput>
        </mat-form-field>
        <br>
        <section *ngIf="gebruiker.hoofdTrainer" >
          <mat-checkbox class="example-margin" [disabled]="true" name="hoofdtrainer"
                        [(ngModel)]="gebruiker.hoofdTrainer">
            Hoofdtrainer
          </mat-checkbox>
        </section>
        <section *ngIf="gebruiker.admin" >
          <mat-checkbox class="example-margin" [disabled]="true" name="admin" [(ngModel)]="gebruiker.admin">
            Administrator
          </mat-checkbox>
        </section>
      </form>
      <mat-card-actions class="actions">
        <div></div>
        <button mat-button mat-raised-button color="primary" [routerLink]="['/logout']">
          Uitloggen
          <mat-icon>exit_to_app</mat-icon>
        </button>
      </mat-card-actions>
    </mat-card-content>
  </mat-card>

</div>
